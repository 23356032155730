import React from "react";

class Footer extends React.Component {
    state = {
        temp_i: null,
        temp_e: null,
        mode: null,
        DRN: null,
        SOL: null,
        FAN: null,
        PWT: null,
        OFF: null,
    };

    componentWillMount = () => {
        this.setState(this.props.estado);
    }

    changeName = (estado) => {
        this.setState(
            estado
        );
    };

    render() {
        return (
            <footer className='footer mt-auto py-3 bg-primary text-white'>
                <div className='footer-content'>
                    <div className="div-left"> I: {this.state.temp_i} | E: {this.state.temp_e}</div>
                    <div className="div-left">{this.state.mode}</div>
                    <div className="clear"></div>
                    <div className="div-righ primero">{this.state.DRN}</div>
                    <div className="div-righ">{this.state.SOL}</div>
                    <div className="div-righ">{this.state.PMP}</div>
                    <div className="div-righ">{this.state.FAN}</div>
                    <div className="div-righ">{this.state.PWT}</div>
                    <div className="div-righ">{this.state.OFF}</div>

                </div>
            </footer>
        )
    }
}

export default Footer;