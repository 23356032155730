import React from "react";

class Cool extends React.Component {
    render() {
        return (
            <div className="content">
                <h1>COOL</h1>
            </div >
        )
    }
}

export default Cool;