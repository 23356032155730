import React from "react";
import { Link } from "react-router-dom";
import { Route } from "react-router-dom";
import { 
  // Button, 
  Navbar, 
  Nav, 
  // NavItem, 
  // NavDropdown, 
  // MenuItem 
} from 'react-bootstrap';

function renderLinkAndHightlightActive(route, text) {
  return (
    <Route
      path={route}
      children={
        ({ match, history, location }) => {
          const activeClass = (!!match && match.isExact) ? 'nav-link--active active' : 'nav-link--normal';
          return (
            <Nav.Link as={Link} to={route} className={activeClass + ' nav-item'}>{text}</Nav.Link >
          );
        }
      }
    />
  );
}

class NavBar extends React.Component {
  render() {
    return (
      <div>
        <Navbar bg="primary" variant="dark" expand="lg">
          <Navbar.Brand href="/">ASTRLR</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              {renderLinkAndHightlightActive('/', 'HOME')}
              {renderLinkAndHightlightActive('/fan', 'FAN')}
              {renderLinkAndHightlightActive('/cool', 'COOL')}
              {renderLinkAndHightlightActive('/auto', 'AUTO')}
              {renderLinkAndHightlightActive('/ind', 'IND')}
              {renderLinkAndHightlightActive('/settings', 'SETTINGS')}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    )
  }
}

export default NavBar;