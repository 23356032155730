
import React from 'react';
import ReactDOM from 'react-dom';
import Routes from './components/Routes';
import { BrowserRouter as Router } from "react-router-dom";
// import { Switch, Route } from "react-router-dom";
// import Header from "./Header";
import NavBar from "./NavBar";
// import NavBar2 from "./Navbar2";
import Footer from "./Footer";
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/index.css';

class App extends React.Component {
    constructor(props) {
        super(props);
        this.cont = 0;
        this.updateFooter = React.createRef();
    }

    handleClick = () => {
        this.cont = this.cont + 1;
        this.setState({
            temp_i: '20º/50%' + (this.cont),
            temp_e: '20º/50%',
            mode: 'COOL',
            DRN: 'DRN',
            SOL: 'SOL',
            FAN: 'FAN',
            PWT: 'PWT',
            OFF: 'OFF',
        });
        this.updateFooter.current.changeName(this.state);
    };

    componentDidMount() {
        this.countdown = setInterval(this.handleClick, 5000);
    }

    componentWillMount() {
        this.setState({
            temp_i: '20º/50%' + (this.cont),
            temp_e: '20º/50%',
            mode: 'COOL',
            DRN: 'DRN',
            SOL: 'SOL',
            FAN: 'FAN',
            PWT: 'PWT',
            OFF: 'OFF',
        });
    }

    componentWillUnmount() {
        clearInterval(this.countdown);
    }

    render() {
        return (
            <div>
                <Router>
                    <NavBar />
                    <Routes />
                    <Footer ref={this.updateFooter} estado={this.state} />
                </Router>
                {/* <button onClick={this.handleClick}>Show real name</button> */}
            </div>
        )
    }
}



// ========================================

ReactDOM.render(
    <App />,
    document.getElementById('root')
);




  // class Square extends React.Component {
//     render() {
//       return (
//         <button className="square">
//           {/* TODO */}
//         </button>
//       );
//     }
//   }

//   class Board extends React.Component {
//     renderSquare(i) {
//       return <Square />;
//     }

//     render() {
//       const status = 'Next player: X';

//       return (
//         <div>
//           <div className="status">{status}</div>
//           <div className="board-row">
//             {this.renderSquare(0)}
//             {this.renderSquare(1)}
//             {this.renderSquare(2)}
//           </div>
//           <div className="board-row">
//             {this.renderSquare(3)}
//             {this.renderSquare(4)}
//             {this.renderSquare(5)}
//           </div>
//           <div className="board-row">
//             {this.renderSquare(6)}
//             {this.renderSquare(7)}
//             {this.renderSquare(8)}
//           </div>
//         </div>
//       );
//     }
//   }