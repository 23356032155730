import React from "react";

class Auto extends React.Component {
    render() {
        return (
            <div className="content">
                <h1>AUTO</h1>
            </div>
        )
    }
}

export default Auto;