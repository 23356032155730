import React from "react";

class Home extends React.Component {
    render() {
        return (
            <div className="content">
                <h1>HOME</h1>
            </div >
        )
    }
}

export default Home;