import React from 'react';
import { Switch, Route } from "react-router-dom";
import Home from "./Home";
import Fan from "./Fan";
import Cool from "./Cool";
import Auto from "./Auto";
import Ind from "./Ind";
import Settings from "./Settings";
import PageNotFound from "./PageNotFound";

class Routes extends React.Component {
    render() {
        return (
            <Switch>
                <Route exact path="/" component={Home} />
                {/* <Route exact path="/users" component={Users} /> */}
                <Route exact path="/fan" component={Fan} />
                <Route exact path="/cool" component={Cool} />
                <Route exact path="/auto" component={Auto} />
                <Route exact path="/ind" component={Ind} />
                <Route exact path="/settings" component={Settings} />
                {/* <Route path="/users/:id" render={props => (
                    <Users match={props.match} />
                )} */}
                />
                <Route component={PageNotFound} />
            </Switch>
        );
    }
}

export default Routes;