import React from "react";

class Fan extends React.Component {
    render() {
        return (
            <div className="content">
                <h1>FAN</h1>
            </div>
        )
    }
}

export default Fan;